import React, { memo } from 'react';
import styled from 'styled-components';
import { Legend, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { getImage } from './Image';

const RadarChartComponent = styled.div`
  width: 100%;
  height: 100%;
`;

function Chart({ data }) {
  const renderCustomizedTick = ({ x, y, radius, payload }) => {
    const imageW = 64;
    const imageH = 64;
    const imageX = x - imageW * 0.5;
    const imageY = y - imageH * 0.5;
    return <image x={imageX} y={imageY} href={getImage(payload.value)} height={imageW} width={imageH} />;
  };

  return (
    <RadarChartComponent>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="guild" tick={renderCustomizedTick} />
          <PolarRadiusAxis />
          <Radar name="Damage" dataKey="damage" stroke="#8884d8" fill="#8884d8" fillOpacity={0.3} animationDuration={500} />
          <Radar name="Kills" dataKey="kills" stroke="#d88488" fill="#d88488" fillOpacity={0.3} animationDuration={500} />
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </RadarChartComponent>
  );
}

export default memo(Chart);
