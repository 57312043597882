import React, { memo } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

const ButtonComponent = styled.div`
  button {
    border-radius: 8px;
    padding: 0 16px;
    height: 48px;
    background-color: ${({ selected }) => (selected ? '#bbddbb' : 'inherit')};
  }
`;

function Button({ id = uuid(), label, selected, onClick }) {
  return (
    <ButtonComponent selected={selected}>
      <button onClick={() => onClick(id)}>{label}</button>
    </ButtonComponent>
  );
}

export default memo(Button);
