import React, { memo } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import Icon from './Icon';

const IconButtonComponent = styled.div`
  button {
    padding: 8px;
    background-color: ${({ selected }) => (selected ? '#bbddbb' : 'inherit')};
  }
`;

function IconButton({ id = uuid(), icon, selected, onClick }) {
  return (
    <IconButtonComponent selected={selected}>
      <button onClick={() => onClick(id)}>
        <Icon icon={icon} />
      </button>
    </IconButtonComponent>
  );
}

export default memo(IconButton);
