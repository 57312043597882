import React, { useState } from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';

import { ChartType, FileDialog, Panel } from './components';

const AppWrapper = styled.div`
  font-family: Montserrat;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Main = styled.main`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr 1fr;
`;

const PanelRow = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr;
`;

export default function App() {
  const [data, setData] = useState(null);
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(true);

  function loadCSV(files) {
    const headers = [
      'time',
      'guilds',
      'battleDuration',
      'archerDamage',
      'assassinDamage',
      'barbarianDamage',
      'knightDamage',
      'priestDamage',
      'wizardDamage',
      'archerKills',
      'assassinKills',
      'barbarianKills',
      'knightKills',
      'priestKills',
      'wizardKills',
      'winner',
    ];
    Papa.parse(files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        const filesData = [];
        results.data.forEach((row) => {
          filesData.push(
            Object.fromEntries(
              row.map((colValue, index) => {
                let value = colValue;
                switch (headers[index]) {
                  case 'winner':
                    value = colValue.toLowerCase();
                    break;

                  case 'guilds':
                    value = colValue.toLowerCase().split('|');
                    break;

                  case 'time':
                    value = Date.parse(colValue);
                    break;

                  default:
                    value = Math.round(+colValue * 100) / 100;
                    break;
                }
                return [headers[index], value];
              }),
            ),
          );
        });
        setData(filesData);
      },
    });
    setIsFileDialogOpen(false);
  }

  return (
    <AppWrapper>
      {data && data.length > 0 && (
        <Main>
          <PanelRow>
            <Panel data={data} chartType={ChartType.Table} />
            <Panel data={data} chartType={ChartType.Bar} />
          </PanelRow>
          <PanelRow>
            <Panel data={data} chartType={ChartType.Pie} />
            <Panel data={data} chartType={ChartType.Radar} />
          </PanelRow>
        </Main>
      )}
      <FileDialog
        title="Load CSV"
        isOpen={isFileDialogOpen}
        onFileChange={loadCSV}
        onClose={() => setIsFileDialogOpen(false)}
      />
    </AppWrapper>
  );
}
