import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

import { ArcherImage, AssassinImage, BarbarianImage, KnightImage, PriestImage, WizardImage } from './images';

const ImageComponent = styled.div`
  width: ${({ width = 64 }) => `${width}px`};
  height: ${({ height = 64 }) => `${height}px`};
`;

export const ImageType = {
  Archer: 'archer',
  Assassin: 'assassin',
  Barbarian: 'barbarian',
  Knight: 'knight',
  Priest: 'priest',
  Wizard: 'wizard',
};

export function getImage(image) {
  switch (image) {
    case ImageType.Archer:
      return ArcherImage;
    case ImageType.Assassin:
      return AssassinImage;
    case ImageType.Barbarian:
      return BarbarianImage;
    case ImageType.Knight:
      return KnightImage;
    case ImageType.Priest:
      return PriestImage;
    case ImageType.Wizard:
      return WizardImage;
    default:
      return null;
  }
}

function Icon({ image, width = 64, height = 64 }) {
  const imageComponent = useMemo(() => {
    return <img src={getImage(image)} alt={image} height={height} />;
  }, [image, height]);
  return (
    <ImageComponent width={width} height={height}>
      {imageComponent}
    </ImageComponent>
  );
}

export default memo(Icon);
