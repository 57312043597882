import React, { memo } from 'react';
import styled from 'styled-components';
import { BattleMode, ChartType, GuildType } from './Constants';
import { IconType } from './Icon';
import IconButton from './IconButton';
import { ImageType } from './Image';
import ImageButton from './ImageButton';
import Spacer from './Spacer';
import ToggleGroup from './ToggleGroup';

const PanelFooterComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 16px;
  background-color: #eeeeee;
`;

function PanelFooter({ guilds, battleMode, chartType, onBattleModeToggle, onGuildToggle, onChartTypeToggle }) {
  return (
    <PanelFooterComponent>
      <label>Chart type:</label>
      <ToggleGroup exclusive selected={chartType} onToggle={onChartTypeToggle}>
        <IconButton id={ChartType.Table} icon={IconType.Table} />
        <IconButton id={ChartType.Bar} icon={IconType.BarChart} />
        <IconButton id={ChartType.Pie} icon={IconType.PieChart} />
        <IconButton id={ChartType.Radar} icon={IconType.RadarChart} />
      </ToggleGroup>
      <Spacer width={8} />
      <label>Battle mode:</label>
      <ToggleGroup exclusive selected={battleMode} onToggle={onBattleModeToggle}>
        <IconButton id={BattleMode.Duel} icon={IconType.Duel} />
        <IconButton id={BattleMode.FreeForAll} icon={IconType.FreeForAll} />
      </ToggleGroup>
      <Spacer width={8} />
      <label>Guilds:</label>
      <ToggleGroup selected={guilds} onToggle={onGuildToggle}>
        <ImageButton id={GuildType.Archer} image={ImageType.Archer} />
        <ImageButton id={GuildType.Assassin} image={ImageType.Assassin} />
        <ImageButton id={GuildType.Barbarian} image={ImageType.Barbarian} />
        <ImageButton id={GuildType.Knight} image={ImageType.Knight} />
        <ImageButton id={GuildType.Priest} image={ImageType.Priest} />
        <ImageButton id={GuildType.Wizard} image={ImageType.Wizard} />
      </ToggleGroup>
    </PanelFooterComponent>
  );
}

export default memo(PanelFooter);
