import React, { memo, useMemo, useState } from 'react';
import styled from 'styled-components';

import { BarChart, BattleMode, ChartType, GuildType, PieChart, RadarChart, TableChart } from './';
import PanelFooter from './PanelFooter';

const PanelComponent = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 60px;
  flex-grow: 0;
  flex-shrink: 0;

  border-width: 0;
  border-style: solid;
  border-color: lightgray;

  &:nth-child(1) {
    border-right-width: 1px;
    border-bottom-width: 1px;
  }
  &:nth-child(2) {
    border-bottom-width: 1px;
  }
  &:nth-child(3) {
    border-right-width: 1px;
  }
`;

const PanelBody = styled.main``;

const allGuilds = [
  GuildType.Archer,
  GuildType.Assassin,
  GuildType.Barbarian,
  GuildType.Knight,
  GuildType.Priest,
  GuildType.Wizard,
];

function Panel({ data, chartType: initialChartType = ChartType.Table }) {
  const [battleMode, setBattleMode] = useState(BattleMode.Duel);
  const [chartType, setChartType] = useState(initialChartType);
  const [guilds, setGuilds] = useState([...allGuilds]);

  const tableData = useMemo(() => {
    const tableData = {};
    let rowData;
    let colData;
    allGuilds.forEach((guildA) => {
      rowData = {};
      allGuilds
        .filter((guildB) => guildA !== guildB)
        .forEach((guildB) => {
          colData = {
            wins: 0,
            damage: 0,
            kills: 0,
          };
          data
            .filter((row) => {
              if (battleMode === BattleMode.Duel) {
                return allGuilds.every((g) => {
                  if (g === guildA || g === guildB) {
                    return row.guilds.includes(g);
                  } else {
                    return !row.guilds.includes(g);
                  }
                });
              } else {
                return row.guilds.includes(guildA) && row.guilds.includes(guildB);
              }
            })
            .forEach((row) => {
              if (row.winner === guildA) {
                colData.wins++;
              }
              colData.damage += Math.round(row[`${guildA}Damage`] / 100);
              colData.kills += row[`${guildA}Kills`];
            });
          rowData[guildB] = colData;
        });
      tableData[guildA] = rowData;
    });
    return tableData;
  }, [data, battleMode]);

  const chartData = useMemo(() => {
    const chartData = [];
    const chartDataMap = {};
    let guildData;
    guilds.forEach((guild) => {
      guildData = {
        guild,
        wins: 0,
        damage: 0,
        kills: 0,
      };
      chartData.push(guildData);
      chartDataMap[guild] = guildData;
    });
    guilds.forEach((guild) => {
      guildData = chartDataMap[guild];
      data
        .filter((row) => {
          return guilds.every((g) => row.guilds.includes(g));
        })
        .forEach((row) => {
          guildData.damage += Math.round(row[`${guild}Damage`] / 100);
          guildData.kills += row[`${guild}Kills`];
        });
    });
    data
      .filter((row) => {
        return guilds.every((g) => row.guilds.includes(g));
      })
      .forEach((row) => {
        guildData = chartDataMap[row.winner];
        if (guildData) {
          guildData.wins++;
        }
      });
    return chartData;
  }, [data, guilds]);

  function toggleGuild(values) {
    setGuilds(Object.keys(values).filter((key) => values[key]));
  }

  function toggleBattleMode(values) {
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        setBattleMode(key);
      }
    });
  }

  function toggleChartType(values) {
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        setChartType(key);
      }
    });
  }

  return (
    <PanelComponent>
      <PanelBody>
        {chartType === ChartType.Bar && <BarChart data={chartData} />}
        {chartType === ChartType.Pie && <PieChart data={chartData} />}
        {chartType === ChartType.Radar && <RadarChart data={chartData} />}
        {chartType === ChartType.Table && <TableChart data={tableData} guilds={guilds} />}
      </PanelBody>
      <PanelFooter
        guilds={guilds}
        battleMode={battleMode}
        chartType={chartType}
        onBattleModeToggle={toggleBattleMode}
        onGuildToggle={toggleGuild}
        onChartTypeToggle={toggleChartType}
      />
    </PanelComponent>
  );
}

export default memo(Panel);
