import React, { memo } from 'react';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';
import { getImage } from './Image';

const BarChartComponent = styled.div`
  width: 100%;
  height: 100%;
`;

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;

  if (!value) return null;

  return (
    <g>
      <image x={x} y={y - width} href={getImage(value)} width={width} height={width} />
    </g>
  );
};

function Chart({ data }) {
  return (
    <BarChartComponent>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width="100%" height="100%" data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="guild" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="wins" fill="#CF1E18" minPointSize={5}>
            <LabelList dataKey="guild" content={renderCustomizedLabel} />
          </Bar>
          <Bar dataKey="damage" fill="#8884d8" minPointSize={10} />
          <Bar dataKey="kills" fill="#82ca9d" minPointSize={10} />
        </BarChart>
      </ResponsiveContainer>
    </BarChartComponent>
  );
}

export default memo(Chart);
