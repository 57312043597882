import React, { memo } from 'react';
import styled from 'styled-components';

const SpacerComponent = styled.div`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`;

function Spacer({ width = 1, height = 1 }) {
  return <SpacerComponent width={width} height={height} />;
}

export default memo(Spacer);
