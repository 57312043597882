import React, { memo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { IconType } from './Icon';
import IconButton from './IconButton';

const FileDialogComponent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(8px);

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.1;
  }
`;

const Dialog = styled.div`
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  border: 1px solid gray;
  border-radius: 8px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 64px auto 32px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  background: white;
`;

const DialogHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 0 16px;
  display: grid;
  grid-template-columns: auto 32px;
  align-items: center;
  background-color: gray;
  height: 100%;

  button {
    outline: 0;
    border: 0;
  }
`;

const DialogBody = styled.div`
  padding: 8px 16px;
`;

const DialogFooter = styled.div``;

const DropZoneContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function FileDialog({ title = 'Dialog', isOpen, width = 600, height = 400, onFileChange, onClose }) {
  const onDrop = useCallback((acceptedFiles) => {
    onFileChange(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!isOpen) return null;
  return (
    <FileDialogComponent>
      <Dialog width={width} height={height}>
        <DialogHeader>
          {title}
          <IconButton icon={IconType.Close} width={32} height={32} onClick={onClose} />
        </DialogHeader>
        <DialogBody>
          <DropZoneContainer {...getRootProps()}>
            <input {...getInputProps()} accept=".csv" />
            {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
          </DropZoneContainer>
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
    </FileDialogComponent>
  );
}

export default memo(FileDialog);
