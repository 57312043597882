import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

const ToggleGroupComponent = styled.div`
  display: flex;
  flex-direction: row;

  div > button {
    border-color: lightgray;
    border-radius: 0;
    border-width: 1px;
    border-left-width: 0;
    appearance: none;
    outline: none;
  }

  div:first-child > button {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left-width: 1px;
  }

  div:last-child > button {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

function ToggleGroup({ selected, exclusive, children, onToggle }) {
  const [buttonStates, setButtonStates] = useState({});

  useEffect(() => {
    const newButtonStates = {};
    React.Children.forEach(children, (child) => {
      newButtonStates[child.props.id] = selected === child.props.id || selected.includes(child.props.id);
    });
    setButtonStates(newButtonStates);
  }, []);

  function onButtonClick(id) {
    let newButtonStates;
    if (exclusive) {
      newButtonStates = {
        ...Object.fromEntries(Object.keys(buttonStates).map((key) => [key, false])),
        [id]: true,
      };
      setButtonStates(newButtonStates);
    } else {
      newButtonStates = {
        ...buttonStates,
        [id]: !buttonStates[id],
      };
      setButtonStates(newButtonStates);
    }
    onToggle(newButtonStates);
  }

  return (
    <ToggleGroupComponent>
      {React.Children.map(children, (child) =>
        React.cloneElement(
          child,
          {
            selected: buttonStates[child.props.id],
            onClick: onButtonClick,
          },
          null,
        ),
      )}
    </ToggleGroupComponent>
  );
}

export default memo(ToggleGroup);
