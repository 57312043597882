import React, { memo } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import Image from './Image';

const ImageButtonComponent = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width = 48 }) => `${width}px`};
    height: ${({ height = 48 }) => `${height}px`};
    background-color: ${({ selected }) => (selected ? '#bbddbb' : 'inherit')};
  }
`;

function ImageButton({ id = uuid(), image, selected, width = 48, height = 48, onClick }) {
  return (
    <ImageButtonComponent width={width} height={height} selected={selected}>
      <button onClick={() => onClick(id)}>
        <Image image={image} width={width} height={height} />
      </button>
    </ImageButtonComponent>
  );
}

export default memo(ImageButton);
