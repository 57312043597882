import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChartBarIcon,
  ChartLineIcon,
  ChartPieIcon,
  ChartRadarIcon,
  ChartScatterIcon,
  DuelIcon,
  FreeForAllIcon,
  TableIcon,
  XMarkIcon,
} from './icons';

const IconComponent = styled.div`
  width: ${({ width = 24 }) => `${width}px`};
  height: ${({ height = 24 }) => `${height}px`};

  svg {
    fill: ${({ color = 'black' }) => color};
    stroke: ${({ color = 'black' }) => color};
  }
`;

export const IconType = {
  ArrowDown: 'arrow-down',
  ArrowUp: 'arrow-up',
  BarChart: 'chart-bar',
  Close: 'xmark',
  Duel: 'swords-laser',
  FreeForAll: 'skull-crossbones',
  LineChart: 'chart-line',
  PieChart: 'chart-pie',
  RadarChart: 'chart-radar',
  ScatterChart: 'chart-scatter',
  Table: 'table',
};

function Icon({ icon, width = 24, height = 24, color = 'black' }) {
  const iconComponent = useMemo(() => {
    switch (icon) {
      case IconType.ArrowDown:
        return <ArrowDownIcon />;
      case IconType.ArrowUp:
        return <ArrowUpIcon />;
      case IconType.BarChart:
        return <ChartBarIcon />;
      case IconType.Close:
        return <XMarkIcon />;
      case IconType.Duel:
        return <DuelIcon />;
      case IconType.FreeForAll:
        return <FreeForAllIcon />;
      case IconType.LineChart:
        return <ChartLineIcon />;
      case IconType.PieChart:
        return <ChartPieIcon />;
      case IconType.RadarChart:
        return <ChartRadarIcon />;
      case IconType.ScatterChart:
        return <ChartScatterIcon />;
      case IconType.Table:
        return <TableIcon />;
      default:
        return null;
    }
  }, [icon]);
  return (
    <IconComponent width={width} height={height} color={color}>
      {iconComponent}
    </IconComponent>
  );
}

export default memo(Icon);
