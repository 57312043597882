import React, { memo } from 'react';
import styled from 'styled-components';
import { GuildType } from './Constants';
import Icon, { IconType } from './Icon';
import Image from './Image';

const TableChartComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  table {
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid lightgray;
    width: 70px;
    height: 70px;
    padding: 0;
    font-size: 12px;
    text-align: center;

    &.empty {
      background-color: lightgray;
    }

    &[disabled] {
      opacity: 0.1;
    }
  }
`;

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 16px;
`;

const arrowIconSize = 10;

const guilds = Object.keys(GuildType).map((key) => key.toLowerCase());

function Chart({ data, guilds: selectedGuilds }) {
  console.log(data);
  return (
    <TableChartComponent>
      <table>
        <thead>
          <tr>
            <th className="empty"></th>
            {guilds.map((guild, index) => (
              <th key={`colheader_${index}`} disabled={!selectedGuilds.includes(guild)}>
                <Image image={guild.toLowerCase()} width="100%" height={60} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {guilds.map((guildA, gAIndex) => {
            return (
              <tr key={`row_${gAIndex}`}>
                <td key={`col_${gAIndex}_image`} disabled={!selectedGuilds.includes(guildA)}>
                  <Image image={guildA.toLowerCase()} width="100%" height={60} />
                </td>
                {guilds.map((guildB, gBIndex) => {
                  if (!data[guildA] || !data[guildA][guildB]) return <td className="empty"></td>;
                  return (
                    <td
                      key={`col_${gAIndex}_${gBIndex}`}
                      disabled={!selectedGuilds.includes(guildA) || !selectedGuilds.includes(guildB)}
                    >
                      <DataRow>
                        <div>W: {data[guildA][guildB].wins}</div>
                        {data[guildA][guildB].wins > data[guildB][guildA].wins && (
                          <Icon icon={IconType.ArrowUp} color="green" width={arrowIconSize} height={arrowIconSize} />
                        )}
                        {data[guildA][guildB].wins < data[guildB][guildA].wins && (
                          <Icon icon={IconType.ArrowDown} color="red" width={arrowIconSize} height={arrowIconSize} />
                        )}
                      </DataRow>
                      <DataRow>
                        <div>D: {Math.round(data[guildA][guildB].damage)}</div>
                        {data[guildA][guildB].damage > data[guildB][guildA].damage && (
                          <Icon icon={IconType.ArrowUp} color="green" width={arrowIconSize} height={arrowIconSize} />
                        )}
                        {data[guildA][guildB].damage < data[guildB][guildA].damage && (
                          <Icon icon={IconType.ArrowDown} color="red" width={arrowIconSize} height={arrowIconSize} />
                        )}
                      </DataRow>
                      <DataRow>
                        <div>K: {data[guildA][guildB].kills}</div>
                        {data[guildA][guildB].kills > data[guildB][guildA].kills && (
                          <Icon icon={IconType.ArrowUp} color="green" width={arrowIconSize} height={arrowIconSize} />
                        )}
                        {data[guildA][guildB].kills < data[guildB][guildA].kills && (
                          <Icon icon={IconType.ArrowDown} color="red" width={arrowIconSize} height={arrowIconSize} />
                        )}
                      </DataRow>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableChartComponent>
  );
}

export default memo(Chart);
