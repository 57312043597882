import React, { memo } from 'react';
import styled from 'styled-components';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { getImage } from './Image';

const PieChartComponent = styled.div`
  width: 100%;
  height: 100%;
`;

const RADIAN = Math.PI / 180;
const COLORS = ['#8AAA1A', '#AD91C8', '#6E3C26', '#CF1E18', '#F0D429', '#60CDE0'];

function Chart({ data }) {
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const textRadius = innerRadius + (outerRadius - innerRadius) * 0.15;
    const textX = cx + textRadius * Math.cos(-midAngle * RADIAN);
    const textY = cy + textRadius * Math.sin(-midAngle * RADIAN);

    const imageRadius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const imageW = 75 + 50 * percent;
    const imageH = 75 + 50 * percent;
    const imageX = cx + imageRadius * Math.cos(-midAngle * RADIAN) - imageW * 0.5;
    const imageY = cy + imageRadius * Math.sin(-midAngle * RADIAN) - imageH * 0.5;

    return (
      <>
        <text x={textX} y={textY} fill="white" textAnchor={textX > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <image x={imageX} y={imageY} href={getImage(data[index].guild)} height={imageW} width={imageH} />
      </>
    );
  };

  return (
    <PieChartComponent>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width="100%" height="100%">
          <Pie
            data={data}
            width="100%"
            height="100%"
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="wins"
            animationBegin={100}
            animationDuration={500}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </PieChartComponent>
  );
}

export default memo(Chart);
