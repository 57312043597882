export const ChartType = {
  Bar: 'bar',
  Line: 'line',
  Pie: 'pie',
  Radar: 'radar',
  Scatter: 'scatter',
  Table: 'table',
};

export const GuildType = {
  Archer: 'archer',
  Assassin: 'assassin',
  Barbarian: 'barbarian',
  Knight: 'knight',
  Priest: 'priest',
  Wizard: 'wizard',
};

export const BattleMode = {
  Duel: 'duel',
  FreeForAll: 'freeForAll',
};
